<template>
  <validation-observer #default="{handleSubmit}">
    <b-card header="Update Country Settings">
      <b-form @submit.prevent>
        <b-form-row>
          <!-- Country -->
          <b-col md="6">
            <b-form-group
              label="Country"
              label-for="country"
            >
              <b-input
                id="country"
                v-model="countrySettings.country"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Calculation Method -->
          <b-col md="6">
            <b-form-group
              label="Calculation Method"
              label-for="calc-method"
            >
              <b-form-select
                id="calc-method"
                v-model="countrySettings.calculation_method"
                :options="settings.calculation_methods"
                value-field="code"
                text-field="name"
              />
            </b-form-group>
          </b-col>

          <!-- Madhab Method -->
          <b-col md="6">
            <b-form-group
              label="Madhab Method"
              label-for="madhab-method"
            >
              <b-form-select
                id="madhab-method"
                v-model="countrySettings.madhab"
                :options="settings.madhab_methods"
                value-field="value"
                text-field="text"
              />
            </b-form-group>
          </b-col>

          <!-- Polar Circle Resolution Methods -->
          <b-col md="6">
            <b-form-group
              label="Polar Circle Resolution Methods"
              label-for="polar-circle-methods"
            >
              <b-form-select
                id="polar-circle-methods"
                v-model="countrySettings.polar_circler_resolution"
                :options="settings.polar_circle_resolution_methods"
                value-field="value"
                text-field="text"
              />
            </b-form-group>
          </b-col>

          <!-- High Latitude Rule Methods -->
          <b-col md="6">
            <b-form-group
              label="High Latitude Rule Methods"
              label-for="high-lat-methods"
            >
              <b-form-select
                id="high-lat-methods"
                v-model="countrySettings.high_latitude_rule_methods"
                :options="settings.high_latitude_rule_methods"
                value-field="value"
                text-field="text"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <div class="text-right">
          <submit-button v-bind="{submit:updateSettings,handleSubmit}" />
        </div>
      </b-form>
    </b-card>
  </validation-observer>
</template>
<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'UpdateCountrySettings',
  components: { SubmitButton },
  data() {
    return {
      countrySettings: {},
      settings: {},
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  created() {
    this.getSettings()
    this.getCountry()
  },
  methods: {
    getSettings() {
      this.$prayers.get('/settings').then(res => {
        this.settings = res.data.data
      })
    },
    getCountry() {
      this.$prayers.get(`/countries/${this.$route.params.id}`).then(res => {
        this.countrySettings = res.data.data
      })
    },
    updateSettings() {
      return this.$prayers.post('/update-country-methods', this.countrySettings).then(() => {
        this.successfulOperationAlert('Country settings is updated successfully')
        this.$router.push({ name: 'country-settings-list' })
      })
    },
  },
}
</script>
<style lang="scss">

</style>
